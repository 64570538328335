@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "fonts",
    "open-iconic-bootstrap",
    "animate",
    "owl.carousel",
    "magnific-popup",
    "aos",
    "ionicons",
    "bootstrap-datepicker",
    "flaticon"
;
